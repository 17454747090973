import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import MyDocumentRus from "./MyDocumentRus";
import { useMemo, useState } from 'react'
import './App.css'
import ReactPDF from '@react-pdf/renderer';
import { Button, DatePicker, Select } from "antd";
import dayjs from 'dayjs';
import logo from './logo.png'
import MyDocumentUae from "./MyDocumentUae";


const importers = {
  moscow: 'ООО "ЛИЧИ РУС", 115230, г. Москва, вн.тер.г. муниципальный округ Нагатино-Садовники, Карширское ш., д.3 к.2 стр.4, ком.51',
  khabarovsk: 'ООО "АЗИЯ ТРЕЙД КОМПАНИ" РОССИЯ, ХАБАРОВСКИЙ КРАЙ, Г.ХАБАРОВСК, УЛ.НЕФТЯНАЯ, Д.1,ОФ.307А'
}

const dateFormat = 'DD/MM/YY';

const countries = [
  {
    countryKey: 'RUS',
    country: 'Россия',
  },
  {
    countryKey: 'UAE',
    country: 'Дубай',
  }
]

function App() {

  const [importer, setImporter] = useState(importers.moscow)
  const [country, setCountry] = useState('RUS')
  const [date, setDate] = useState(dayjs().format(dateFormat))

  const downloadButton = useMemo(() => {
    switch (country) {
      case 'RUS':
        return (
            <PDFDownloadLink document={<MyDocumentRus date={date} importer={importer}/>} fileName="somename.pdf">
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Скачать'
              }
            </PDFDownloadLink>
         )
      case 'UAE':
        return (
            <PDFDownloadLink document={<MyDocumentUae date={date}/>} fileName="somename.pdf">
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Скачать'
              }
            </PDFDownloadLink>
          )
    }
  }, [country, date, importer])

  return (
    //Для верски

/*            <PDFViewer height={2000} width={800}>
     <MyDocumentUae date={date} importer={importer}/>
     </PDFViewer>*/


    //Для скачивания

    <div className={'App'}>
      <div className={'container'}>
        <div className={'logoContainer'}>
          <img src={logo} alt="logo" className={'logo'}/>
        </div>
        <h2>Lichi Label</h2>
        <div className={'switchContainer'}>
          {countries.map(item =>
            <div
              className={`switchButton ${country === item.countryKey ? 'active' : ''}`}
              onClick={() => setCountry(item.countryKey)}
            >
              {item.country}
            </div>
          )}
        </div>
        {country === 'RUS' &&
          <div className={'mb'}>
            <Select
              listHeight={128}
              defaultValue={importers.moscow}
              style={{ width: '100%' }}
              onChange={setImporter}
              options={[
                { value: importers.moscow, label: importers.moscow },
                { value: importers.khabarovsk, label: importers.khabarovsk },
              ]}
            />
          </div>
        }
        <div className={'mb'}>
          <DatePicker
            style={{ width: '100%' }}
            defaultValue={dayjs()}
            format={dateFormat}
            onChange={(_, dateString) => setDate(dateString)}
          />
        </div>
        <div className={"buttonContainer"}>
          <Button danger className={'buttonSend'} type={"primary"}>
            {downloadButton}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default App;

