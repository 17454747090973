import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import FuturaBookc from './FUTURABOOKC.OTF'
import FuturaLight from './FUTURALIGHTC.OTF'


Font.register({
  family: 'Futura',
  fonts: [
    { src: FuturaLight, fontWeight: 300 }, // font-style: normal, font-weight: normal
    { src: FuturaBookc, fontWeight: 400 },
  ]
})


const styles = StyleSheet.create({
  page: {
    padding: '5px 10px',
    fontFamily: 'Futura',
  },
  headContainer: {
    width: `calc(100% - 20px)`,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '9px',
    flexDirection: 'row'
  },
  leftHeadContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '23px',
    justifyContent: 'space-between',
  },
  leftTextHeadSmall: {
    fontSize: '5px'
  },
  text: {
    color: 'red'
  },
  titleContainer: {
    margin: '18px 0',
    display: 'flex',
    flexDirection: 'row',
    width: `calc(100% - 20px)`,
    justifyContent: 'center',
  },
  titleText: {
    fontSize: '6px',
    fontWeight: 400
  },
  info: {
    textAlign: 'justify',
    fontSize: '5.5px',
    marginBottom: '28px',
    fontWeight: 300
  },
  infoTitle: {
    fontWeight: 400
  },
  bottomContainer: {
    fontSize: '6px',
    display: 'flex',
    flexDirection: 'row',
    width: `calc(100% - 20px)`,
    justifyContent: 'center',
    fontWeight: 400
  },
});

const MyDocumentRus = ({ importer, date }) => (
  <Document>
    <Page size={[113.4, 198.4]} style={styles.page}> {/*//size={{width: 113.386, height: 198.425}}>*/}
      <View style={styles.headContainer}>
        <View style={styles.leftHeadContainer}>
          <Text style={styles.leftTextHeadSmall}>TM</Text>
          <Text >Lichi</Text>
        </View>
        <Text>EAC</Text>
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.titleText}>Изготовлено в Китае</Text>
      </View>
      <View style={styles.info}>
        <Text>
          <Text style={styles.infoTitle}>Импортер: </Text>
          {importer}
        </Text>
      </View>
      <View style={styles.info}>
        <Text>
          <Text style={styles.infoTitle}>Изготовитель: </Text>
          Guangzhou Lichi Limited LLC, Room 206-208, Building C, No.465, Xicha Road, Tongde Street, Baiyun District, Guangzhou
        </Text>
      </View>
      <View style={styles.bottomContainer}>
        <Text style={{marginBottom: '5px'}}>ТР ТС 017/2011</Text>
      </View>
      <View style={styles.bottomContainer}>
        <Text>Дата изготовления: {date}</Text>
      </View>
    </Page>
  </Document>
);

export default MyDocumentRus